export const EVENTS_NAMES = {
  CALENDAR_INITIALIZED: 'calendar_initialized',
  CALENDAR_SUBMITTED: 'calendar_submitted',
  CALENDAR_CHANGE_DATES: 'calendar_change_dates',
  BOOKING_FORM_SUBMITTED: 'booking_form_submitted',
  BOOKING_FORM_SUBMISSION_ERROR: 'booking_form_submission_error',
  SUMMARY_SCREEN_VIEW: 'summary_screen_view',
  QUESTION_MESSAGE_SENT: 'question_message_sent',
};

/* eslint-disable */
export const logEvent = (name, params) => {
  try {
    if (
      analytics &&
      typeof analytics === 'object' &&
      typeof analytics.logEvent === 'function'
    ) {
      analytics.logEvent(name, params);
    }
  } catch (error) {
    console.warn(error); // eslint-disable-line no-console
  }
};
/* eslint-disable */
