import { makeAutoObservable, runInAction } from 'mobx';
import { sendData, POST_MESSAGE_KEYS } from '@utils/communication';
import { API, APIRoutes, setAuthenticationToken } from '@api';
import { getCookie, setCookie, removeCookie, KEYS } from '@utils/cookies';

export class WidgetStore {
  initialized = false;

  isLoading = false;

  JWTtoken = '';

  iframeId = null;

  error = null;

  authToken = null;

  tokenExpiration = null;

  isAuth = false;

  widgetType = null;

  queryParams = null;

  reinitialize = false;

  parentWindowHeight = 0;

  customStyle = null;

  constructor() {
    makeAutoObservable(this);
  }

  setInitialized = value => {
    this.initialized = value;
  };

  toggleReinitialized = () => {
    this.reinitialize = false;
  };

  authenticate = async () => {
    try {
      const { apikey } = this.queryParams;
      if (!apikey) {
        throw new Error('Missing api key parameter');
      }

      const cookieToken = getCookie(KEYS.AUTH_TOKEN);
      if (cookieToken) {
        runInAction(() => {
          this.authToken = cookieToken;
          this.isAuth = true;
        });
      } else {
        const { data } = await API.post(APIRoutes.CREATE_TOKEN, {
          authentication: {
            api_key: apikey,
          },
        });

        setCookie(KEYS.AUTH_TOKEN, data.authToken, new Date(data.exp));
        setAuthenticationToken(data.authToken);

        runInAction(() => {
          this.authToken = data.authToken;
          this.tokenExpiration = data.exp;
          this.isAuth = true;
        });
      }
    } catch (err) {
      runInAction(() => {
        this.error = err.message || err.error;
        this.isAuth = false;
      });
    }
  };

  init = async params => {
    try {
      runInAction(() => {
        this.queryParams = params;
        this.parentWindowHeight = parseInt(params.h, 10);
      });

      if (!this.initialized || this.reinitialize) {
        runInAction(() => {
          this.isLoading = true;
        });

        await this.authenticate();

        runInAction(() => {
          this.iframeId = params.fid;
          this.widgetType = params.type;
          if (!this.initialized) {
            this.iframeInitializeCommunication(params.yid);
          }
          this.initialized = true;
        });
      }
    } catch (error) {
      console.warn(error); // eslint-disable-line
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  fitIframeToContent = height => {
    if (height >= 300) {
      const additionalPadding = 30;
      const payload = {
        action: POST_MESSAGE_KEYS.RESIZE_WIDGET,
        height: height + additionalPadding,
        iframeId: this.iframeId,
      };
      sendData(payload);
    }
  };

  redirectFromIframe = url => {
    const payload = {
      action: POST_MESSAGE_KEYS.REDIRECT,
      redirectURL: url,
      iframeId: this.iframeId,
    };
    sendData(payload);
  };

  toggleParentWindowScrollLock = lock => {
    const payload = {
      action: 'yacht:lock-parent-scroll',
      lock,
      iframeId: this.iframeId,
    };
    sendData(payload);
  };

  iframeInitializeCommunication = () => {
    const payload = {
      action: POST_MESSAGE_KEYS.INITIALIZED,
      iframeId: this.iframeId,
    };
    sendData(payload);

    const eventMethod = window.addEventListener
      ? 'addEventListener'
      : 'attachEvent';
    const messageEvent =
      eventMethod === 'attachEvent' ? 'onmessage' : 'message';

    window.addEventListener(
      messageEvent,
      message => {
        try {
          if (message && message.data) {
            const {
              data: { customStyle },
            } = message;
            runInAction(() => {
              this.customStyle = customStyle;
            });
          }
        } catch (error) {
          console.warn(error); // eslint-disable-line
        }
      },
      false,
    );
  };

  tokenExpired = async () => {
    try {
      removeCookie(KEYS.AUTH_TOKEN);
      console.log('@@@@ Token expired, trying to renew'); // eslint-disable-line no-console
      runInAction(() => {
        this.reinitialize = true;
      });
    } catch (error) {
      console.warn({ error }); // eslint-disable-line
    }
  };

  scrollWindowToTheTopOfIframe = isVisible => {
    const payload = {
      action: 'yacht:scroll-window-to-iframe',
      isVisible,
      iframeId: this.iframeId,
    };
    sendData(payload);
  };
}

export default new WidgetStore();
