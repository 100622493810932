/* eslint-disable */
import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes } from '@api';
import { calendarStore, widgetStore, routerStore, summaryStore } from '@stores'; // eslint-disable-line
import routes from '@routes';
import { formatDate } from '@utils/formatters';
import { logEvent, EVENTS_NAMES } from '@utils/analytics';

export class BookingStore {
  isLoading = false;

  price = null;

  offerId = null;

  processing = false;

  paymentRedirectUrl = null;

  error = null;

  regulationsModalIsVisible = false;

  regulationsContent = null;

  regulationsAccepted = false;

  constructor() {
    makeAutoObservable(this);
  }

  submitBookingForm = async (formData, setError, tab) => {
    runInAction(() => {
      this.processing = true;
    });
    try {
      const { startDate, endDate, yachtId } = calendarStore;
      const { host } = widgetStore.queryParams;

      const payload = {
        reservation: {
          start_date: formatDate(startDate),
          end_date: formatDate(endDate),
          url: host,
          ...formData,
        },
      };

      let invoiceStreetFormatted;
      if (formData.invoice_needed) {
        invoiceStreetFormatted = `${invoice_street} ${invoice_street_nr}${
          invoice_street_local ? `/${invoice_street_local}` : ''
        }`;

        payload.booking.invoice_street = invoiceStreetFormatted;
      }

      const {
        data: {
          data: { redirectUrl },
        },
      } = await API.post(APIRoutes.BOOK(yachtId), payload);

      runInAction(() => {
        this.paymentRedirectUrl = redirectUrl;
      });
      tab.location = redirectUrl;

      logEvent(EVENTS_NAMES.BOOKING_FORM_SUBMITTED, {
        yacht_id: calendarStore.yachtId,
        yacht_name: summaryStore.summary.yachtName,
        offer_id: this.offerId,
        price: this.price,
      });
      setTimeout(() => {
        routerStore.push(`${routes.payment}${routerStore.location.search}`);
        runInAction(() => {
          this.processing = false;
        });
      }, 5000);
    } catch (error) {
      if (tab && typeof tab.close === 'function') {
        tab.close();
      }
      console.warn({ error }); // eslint-disable-line no-console

      if (error?.errors) {
        Object.keys(error.errors).forEach(err => {
          if (err === 'global') {
            runInAction(() => {
              [this.error] = error.errors[err];
            });
            logEvent(EVENTS_NAMES.BOOKING_FORM_SUBMISSION_ERROR, {
              yacht_id: calendarStore.yachtId,
              yacht_name: summaryStore.summary.yachtName,
              offer_id: this.offerId,
              price: this.price,
              error: error.errors[err],
            });
          } else if (err === 'street') {
            setError('street', { message: error.errors[err][0] });
            setError('street_nr', { message: error.errors[err][0] });
          } else if (err === 'invoice_street') {
            setError('invoice_street', { message: error.errors[err][0] });
            setError('invoice_street_nr', {
              message: error.errors[err][0],
            });
          } else {
            setError(err, { message: error.errors[err][0] });
          }
        });
      }
      runInAction(() => {
        this.processing = false;
      });
    }
  };

  setProcessing = () => {
    this.processing = true;
  };

  showRegulations = async () => {
    this.isLoading = true;

    try {
      const { yachtId } = calendarStore;

      const {
        data: { content },
      } = await API.get(APIRoutes.REGULATIONS(yachtId));

      this.setRegulationsModalIsVisible(true);

      runInAction(() => {
        this.regulationsContent = content;
      });
    } catch (error) {
      console.warn(error); // eslint-disable-line no-console
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  setRegulationsModalIsVisible = isVisible => {
    this.regulationsModalIsVisible = isVisible;
    if (!isVisible) {
      this.regulationsContent = null;
    }
  };

  acceptRegulationsFromModal = () => {
    this.regulationsModalIsVisible = false;
    this.regulationsAccepted = true;
  };

  setRegulationsAccepted = value => {
    this.regulationsAccepted = value;
  };
}

export default new BookingStore();
