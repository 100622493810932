import React, { useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { CSSTransition } from 'react-transition-group';

import {
  ModalBox,
  ModalBody,
  ModalButtons,
  ModalOverlay,
  ModalCloseButton,
  ModalHeader,
} from '@containers/Summary/style';
import Button, { VARIANTS } from '@components/Button';
import CloseIcon from '@assets/icons/close-dark.svg';

const RegulationsModal = ({
  bookingStore: {
    regulationsContent,
    setRegulationsModalIsVisible,
    acceptRegulationsFromModal,
    regulationsModalIsVisible,
  },
  widgetStore: { parentWindowHeight, scrollWindowToTheTopOfIframe },
}) => {
  const isMounted = useRef(false);
  useEffect(() => {
    if (isMounted.current) {
      scrollWindowToTheTopOfIframe(regulationsModalIsVisible);
    }
    isMounted.current = true;
  }, [regulationsModalIsVisible]);

  const closeModalHandler = useCallback(() => {
    setRegulationsModalIsVisible(false);
  });

  return (
    <>
      <CSSTransition
        in={regulationsModalIsVisible}
        timeout={300}
        classNames="fade"
        unmountOnExit
      >
        <ModalOverlay />
      </CSSTransition>
      <CSSTransition
        in={regulationsModalIsVisible}
        timeout={300}
        classNames="loader"
        unmountOnExit
      >
        <ModalBox maxHeight={parentWindowHeight}>
          <ModalHeader>REGULAMIN I POLITYKA PRYWATNOŚCI</ModalHeader>
          <ModalCloseButton type="button" onClick={closeModalHandler}>
            <img src={CloseIcon} alt="" />
          </ModalCloseButton>
          <ModalBody>
            <span dangerouslySetInnerHTML={{ __html: regulationsContent }} />
          </ModalBody>
          <ModalButtons>
            <Button variant={VARIANTS.TEXT} onClick={closeModalHandler}>
              Zamknij
            </Button>
            <Button
              variant={VARIANTS.PRIMARY}
              onClick={acceptRegulationsFromModal}
            >
              Akceptuję
            </Button>
          </ModalButtons>
        </ModalBox>
      </CSSTransition>
    </>
  );
};

RegulationsModal.propTypes = {
  bookingStore: PropTypes.shape({
    setRegulationsModalIsVisible: PropTypes.func,
    regulationsContent: PropTypes.string,
    acceptRegulationsFromModal: PropTypes.func,
    regulationsModalIsVisible: PropTypes.bool,
  }).isRequired,
  widgetStore: PropTypes.shape({
    parentWindowHeight: PropTypes.number,
    scrollWindowToTheTopOfIframe: PropTypes.func,
  }).isRequired,
};

export default inject(
  'summaryStore',
  'bookingStore',
  'widgetStore',
)(observer(RegulationsModal));
