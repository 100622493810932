import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { breakpoint } from '@styles/breakpoints';
import { Scrollbars } from 'react-custom-scrollbars';

export const Wrapper = styled.div`
  ${({ error }) =>
    error === 'true'
      ? css`
          min-height: 300px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        `
      : null}
`;

export const Error = styled.div`
  margin-bottom: 30px;
`;

export const ErrorIcon = styled.img`
  display: block;
  width: 60px;
  margin-bottom: 15px;
`;

export const Column = styled.div`
  flex: 1 0 40%;

  & + & {
    flex: 1 0 60%;
  }

  @media screen and ${breakpoint.md} {
    flex-basis: 100%;

    & + & {
      flex-basis: 100%;
    }
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background-color: rgba(60, 60, 60, 0.9);
`;

export const ModalBox = styled.div`
  position: fixed;
  width: 94%;
  height: 94%;
  top: 15px;
  left: 3%;
  z-index: 1000;
  background-color: #fff;
  padding: 50px 15px 15px 15px;
  box-shadow: 0 0 17px rgb(0 0 0 / 10%);
  max-height: ${({ maxHeight }) => maxHeight - 100}px;
`;

export const ModalCloseButton = styled.button`
  display: block;
  position: absolute;
  top: 4px;
  right: 4px;
  border: none;
  cursor: pointer;
  padding: 5px;
  margin: 0;
  z-index: 10;

  img {
    display: block;
    width: 24px;
    height: 24px;
  }

  &:focus {
    outline: none;
  }
`;

export const ModalHeader = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.modal.header.background};
`;

export const ModalBody = styled(Scrollbars)`
  width: 100%;
  height: calc(100% - 70px) !important;
  h1 {
    text-transform: uppercase;
    display: block;
    text-align: center;
  }
  h2 {
  }
  h3 {
    display: block;
    text-align: center;
    position: relative;
    font-size: 16px;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.1);
      position: absolute;
      top: calc(50% - 2px);
      z-index: -1;
    }

    &:after {
      top: calc(50% + 2px);
    }

    > span {
      display: inline-block;
      background-color: #fff;
      padding: 0 15px;
    }
  }

  p {
    display: block;
    text-align: justify;
    margin: 15px 0;
    font-size: 12px;
    line-height: 16px;
  }

  li {
    font-size: 12px;
    line-height: 16px;
  }

  ul {
    padding-left: 15px;
  }
`;

export const ModalButtons = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
`;
