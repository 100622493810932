import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import Button, { VARIANTS } from '@components/Button';
import { Controls } from '@components/CalendarActions/style';

const CalendarActions = ({
  calendarStore: { resetDates, submitCalendar },
  showSelectButton,
}) => {
  const { t } = useTranslation();

  return (
    <Controls>
      <Button variant={VARIANTS.TEXT} onClick={resetDates}>
        {t('resetDates')}
      </Button>
      {showSelectButton && (
        <Button variant={VARIANTS.PRIMARY} onClick={submitCalendar}>
          {t('checkPrice')}
        </Button>
      )}
    </Controls>
  );
};

CalendarActions.propTypes = {
  showSelectButton: PropTypes.bool.isRequired,
  calendarStore: PropTypes.shape({
    resetDates: PropTypes.func,
    submitCalendar: PropTypes.func,
  }).isRequired,
};

export default inject('calendarStore')(observer(CalendarActions));
