import { makeAutoObservable, runInAction } from 'mobx';
import { toast } from 'react-toastify';
import moment from 'moment';
import { API, APIRoutes } from '@api';
import { calendarStore, routerStore, widgetStore, bookingStore } from '@stores';
import routes from '@routes';
import { formatDate } from '@utils/formatters';
import { logEvent, EVENTS_NAMES } from '@utils/analytics';

const RESET_CALENDAR_ON_CHANGE_DATES = false;

export class SummaryStore {
  isLoading = false;

  summary = {};

  errors = {};

  isBookingAvailable = false;

  constructor() {
    makeAutoObservable(this);
  }

  getBookingDetails = async () => {
    try {
      const { startDate, endDate, yachtIds } = calendarStore;
      if (
        !startDate ||
        !endDate ||
        (typeof startDate.isValid === 'function' &&
          (!startDate.isValid() || !endDate.isValid()))
      ) {
        routerStore.push(`${routes.root}${routerStore.location.search}`);
      } else {
        const from = formatDate(startDate);
        const to = formatDate(endDate);

        const { data } = await API.get(
          APIRoutes.YACHT_AVAILABILITY(yachtIds, from, to),
        );

        if (startDate.isSame(new Date(), 'day')) {
          data.data.restrictions = `Rezerwacja rozpoczynająca się od dnia dzisiejszego musi być przez nas potwierdzona.
            Zostaw wiadomość, a skontaktujemy się najszybciej jak to możliwe.`;
          data.data.available = false;
        }

        runInAction(() => {
          this.summary = data.data;
          this.isBookingAvailable = data.data.bookingAvailable;
          bookingStore.price = data.data.price;
          bookingStore.offerId = data.data.offerId;
          // set selected by API yacht id
          calendarStore.yachtId = data.data.yachtId;
          calendarStore.startDate = moment(from);
          calendarStore.endDate = moment(to);
        });

        logEvent(EVENTS_NAMES.SUMMARY_SCREEN_VIEW, {
          yacht_id: calendarStore.yachtId,
          yacht_name: this.summary.yachtName,
          is_available: this.summary.available,
          is_booking_available: !!this.summary.bookingAvailable,
        });
      }
    } catch (error) {
      console.warn(error); // eslint-disable-line
    } finally {
      runInAction(() => {
        widgetStore.isLoading = false;
        widgetStore.initialized = true;
      });
    }
  };

  sendQuestion = async (formData, setError) => {
    try {
      runInAction(() => {
        this.isLoading = true;
      });
      const { host } = widgetStore.queryParams;
      const startDate = formatDate(calendarStore.startDate);
      const endDate = formatDate(calendarStore.endDate);
      const { status } = await API.post(
        APIRoutes.SEND_QUESTION(calendarStore.yachtId),
        {
          message: {
            ...formData,
            date_from: startDate,
            date_to: endDate,
            url: host,
          },
        },
      );

      if (status === 204) {
        toast.success('Zapytanie zostało wysłane');
        logEvent(EVENTS_NAMES.QUESTION_MESSAGE_SENT, {
          yacht_id: calendarStore.yachtId,
          yacht_name: this.summary.yachtName,
          from_data: startDate,
          to_date: endDate,
        });
        routerStore.push(`${routes.root}${routerStore.location.search}`);
        calendarStore.resetDates();
      }
    } catch (error) {
      const { errors } = error;
      if (errors) {
        Object.keys(errors).forEach(fieldName => {
          setError(fieldName, { message: errors[fieldName] });
        });
      }
      console.warn(error); // eslint-disable-line
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  };

  changeDates = () => {
    routerStore.push(`${routes.root}${routerStore.location.search}`);
    if (RESET_CALENDAR_ON_CHANGE_DATES) {
      setTimeout(() => calendarStore.resetDates(), 100);
    }
    logEvent(EVENTS_NAMES.CALENDAR_CHANGE_DATES, {
      yacht_id: calendarStore.yachtId,
    });
  };

  reset = () => {
    runInAction(() => {
      this.summary = {};
    });
  };
}

export default new SummaryStore();
