export const POST_MESSAGE_KEYS = {
  RESIZE_WIDGET: 'yacht:resize-widget',
  REDIRECT: 'yacht:redirect',
  INITIALIZED: 'yacht:initialized',
};

export const sendData = payload => {
  try {
    const { parent } = window;
    parent.postMessage(JSON.stringify(payload), '*');
  } catch (error) {
    console.warn(error); // eslint-disable-line
  }
};

export const iSJsonString = str => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
