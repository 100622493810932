import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';

export const Controls = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 30px;
  position: relative;
  z-index: 2;

  button + button {
    margin-left: 20px;
  }

  @media screen and ${breakpoint.sm} {
    padding-right: 0;
    justify-content: center;
  }
`;
