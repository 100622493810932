/* eslint-disable */
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const VARIANTS = {
  PRIMARY: 'PRIMARY',
  TEXT: 'TEXT',
};

export const SIZES = {
  SMALL: 'SMALL',
  DEFAULT: 'DEFAULT',
};

const PRIMARY = theme => css`
  background-color: ${theme.buttons.primary.background};
  color: ${theme.buttons.primary.textColor};
`;

const TEXT = theme => css`
  font-size: ${theme.buttons.text.fontSize}px;
  color: ${theme.buttons.text.textColor};
  font-weight: 500;
  position: relative;
  padding: 5px 0;

  &:before {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: ${theme.buttons.text.textColor};
    position: absolute;
    bottom: 0;
  }
`;

const SMALL = () => css`
  font-size: 12px;
  padding: 8px 10px;
  font-weight: 400;
`;

const VARIANT = {
  [VARIANTS.PRIMARY]: PRIMARY,
  [VARIANTS.TEXT]: TEXT,
};

const SIZE = {
  [SIZES.SMALL]: SMALL,
};

export const Button = styled.button`
  font-family: ${({ theme }) => theme.fonts.default};
  font-size: 16px;
  font-weight: 400;
  padding: 15px 25px;
  border: none;
  background-color: transparent;
  margin: 0;
  text-transform: uppercase;
  display: inline-flex;
  cursor: pointer;
  white-space: nowrap;

  &:focus,
  &:active {
    outline: none;
  }

  ${({ variant, theme }) => {
    if (variant) {
      return VARIANT[variant](theme);
    }
    return null;
  }}

  ${({ size, theme }) => {
    if (size && size !== SIZES.DEFAULT) {
      return SIZE[size](theme);
    }
    return null;
  }}
`;
