import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Day = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border-color 0.1s ease-in-out, color 0.1s ease-in-out,
    background-color 0.1s ease-in-out;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    display: none;
    width: 150%;
    height: 150%;
    background-color: ${({ theme }) => theme.calendar.day.blocked.background};
    position: absolute;
    top: 0;
  }

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    width: 100%;
    height: 100%;
    border: 1px transparent solid;
  }

  ${({ firstInRange, status }) =>
    firstInRange && !status.has('blocked')
      ? css`
          &:before {
            display: block;
            transform: rotate(-45deg);
            transform-origin: top right;
            right: 0;
          }
        `
      : null}

  ${({ lastInRange, status }) =>
    (lastInRange && !status.has('blocked')) ||
    (lastInRange && status.has('selected-start'))
      ? css`
          &:before {
            display: block;
            transform: rotate(45deg);
            transform-origin: top right;
            right: 0%;
          }
        `
      : null}


  ${({ status, theme }) => {
    let style;
    if (
      status.has('blocked') &&
      !status.has('blocked-calendar') &&
      !status.has('selected-start')
    ) {
      style = css`
        > span {
          background-color: ${theme.calendar.day.blocked.background};
          text-decoration: line-through;
        }
      `;
    }

    if (status.has('blocked-out-of-range')) {
      style = css`
        > span {
          background-color: ${theme.calendar.day.blockedOutOfRange.background};
        }
      `;
    }

    if (status.has('today')) {
      style = css`
        > span {
          background-color: transparent;
          border: 5px solid ${theme.calendar.day.today.background};
        }
      `;
    }

    return style;
  }}
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.general.background};
  display: inline-block;
  width: 100%;
  padding-left: ${({ paddingLeft }) => paddingLeft}px;

  .DayPickerNavigation__horizontal {
    width: ${({ paddingLeft }) =>
      paddingLeft ? `calc(100vw - ${paddingLeft}px)` : '100vw'};
  }

  .CalendarDay__blocked_calendar {
    ${Day} {
      &:before {
        display: none;
      }
    }
  }

  ${({ withMinHeight, daySize }) =>
    withMinHeight
      ? css`
          .DayPicker,
          .DayPicker__horizontal {
            height: ${daySize * 6 + 90}px;
          }
        `
      : null};
`;
