import React from 'react';
import PropTypes from 'prop-types';
import { Button as Btn, VARIANTS, SIZES } from '@components/Button/style';

export { VARIANTS, SIZES } from '@components/Button/style';

const Button = ({
  children,
  onClick,
  size,
  variant,
  style,
  className,
  isDisabled,
}) => (
  <Btn
    onClick={onClick}
    variant={variant}
    size={size}
    style={style}
    className={className}
    disabled={isDisabled}
  >
    {children}
  </Btn>
);

Button.defaultProps = {
  size: SIZES.DEFAULT,
  onClick: null,
  className: null,
  style: {},
  isDisabled: false,
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  variant: PropTypes.oneOf(Object.values(VARIANTS)).isRequired,
  size: PropTypes.oneOf(Object.values(SIZES)),
  onClick: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
};

export default Button;
