import moment from 'moment';
import numeral from 'numeral';

// load a locale
numeral.register('locale', 'pl', {
  delimiters: {
    thousands: ' ',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  ordinal: number => (number === 1 ? 'er' : 'ème'),
  currency: {
    symbol: 'zł',
  },
});

// switch between locales
numeral.locale('pl');

export const formatDate = (date, format = 'YYYY-MM-DD') =>
  moment(date).format(format);

export const formatCurrency = (value, withCurrency = false) =>
  numeral(value).format(`0,0[.]00${withCurrency ? ' $' : ''}`);
