export const THEMES = {
  DEFAULT: 'DEFAULT',
};

const defaultTheme = {
  name: THEMES.DEFAULT,
  theme: palette => ({
    fonts: {
      default: "'Roboto', sans-serif",
    },
    palette,
    buttons: {
      primary: {
        background: palette.primary,
        textColor: '#ffffff',
      },
      text: {
        fontSize: 12,
        textColor: '#333333',
      },
    },
    calendar: {
      background: 'transparent',
      month: {
        background: 'transparent',
      },
      day: {
        default: {
          background: 'transparent',
        },
        today: {
          background: '#ffae00',
        },
        blocked: {
          background: 'rgba(0, 0, 0, 0.1)',
        },
        blockedOutOfRange: {
          background: 'transparent',
        },
        selected: {
          background: palette.primary,
          range: `${palette.primary}80`,
        },
      },
    },
    fontSizes: {
      h1: '30px',
      h2: '28px',
      h3: '16px',
      h4: '15px',
      nav: '13px',
      navCollapsed: '11px',
      body: '13px',
      bodySmall: '12px',
    },
    notificationBoxes: {
      warning: {
        background: '#ffae00',
        textColor: '#333333',
      },
    },
    spacing: {
      boxBottomSpacing: '15px',
    },
    modal: {
      header: {
        background: 'rgba(0, 0, 0, 0.05)',
      },
    },
    headers: {
      dark: {
        background: palette.tertiary,
        text: '#ffffff',
      },
      light: {
        background: palette.quaternary,
        text: '#666666',
      },
    },
    general: {
      background: 'transparent',
    },
    loader: {
      backgroundColor: '#fff',
    },
  }),
};

export default [defaultTheme];
