import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import intervalPlural from 'i18next-intervalplural-postprocessor';

i18n
  .use(intervalPlural)
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: 'pl',
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    ns: ['common', 'summary', 'booking'],
    defaultNS: 'common',
    load: 'languageOnly',
    // react-i18next options
    react: {
      useSuspense: true,
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
      addPath: '/locales/add/{{lng}}/{{ns}}',
    },
  });

export default i18n;
