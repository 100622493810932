import React from 'react';
import styled from '@emotion/styled';
import { Global, css, useTheme } from '@emotion/react';

export const LoaderIcon = styled.div`
  display: block;
  animation: spin 2s linear infinite;
  width: 64px;
  height: 64px;
  margin-bottom: 15px;

  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

export const SuspenseLoader = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const GlobalStyle = () => {
  const theme = useTheme();
  return (
    <Global
      styles={css`
        @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');

        *,
        *:after,
        *:before {
          box-sizing: border-box;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        body {
          color: #000;
          font-family: ${theme.fonts.default};
          padding: 0;
          margin: 0;
          overflow: hidden;
          background-color: ${theme.general.background};
        }

        .loader-enter {
          opacity: 0;
          transform: scale(0.9);
        }
        .loader-enter-active {
          opacity: 1;
          transform: translateX(0);
          transition: opacity 300ms, transform 300ms;
        }
        .loader-exit {
          opacity: 1;
        }
        .loader-exit-active {
          opacity: 0;
          transform: scale(0.9);
          transition: opacity 300ms, transform 300ms;
        }

        .fade-enter {
          opacity: 0;
        }
        .fade-enter-active {
          opacity: 1;
          transition: opacity 300ms, transform 300ms;
        }
        .fade-exit {
          opacity: 1;
        }
        .fade-exit-active {
          opacity: 0;
          transition: opacity 300ms, transform 300ms;
        }

        strong {
          font-weight: 700;
        }
      `}
    />
  );
};

export default GlobalStyle;
