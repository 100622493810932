import axios from 'axios';
import { getCookie, KEYS } from '@utils/cookies';
import { widgetStore } from '@stores';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'site-origin':
      process.env.NODE_ENV === 'development'
        ? window.location.origin
        : document.referrer,
  },
});

const AUTH_TOKEN = getCookie(KEYS.AUTH_TOKEN);
if (AUTH_TOKEN) {
  API.defaults.headers.common.Authorization = `${AUTH_TOKEN}`;
}

const setApiLanguage = lang => {
  API.defaults.headers.common['Accept-Language'] = lang;
};

API.interceptors.response.use(
  response => response,
  error => {
    if (error.message === 'Network Error') {
      return Promise.reject(error);
    }

    if (error.response?.status === 401) {
      console.warn('unauthorized'); // eslint-disable-line
      widgetStore.tokenExpired();
    }
    return Promise.reject(error.response.data);
  },
);

const setAuthenticationToken = token => {
  API.defaults.headers.common.Authorization = token;
};

const APIRoutes = {
  CREATE_TOKEN: '/auth/sign_in', // POST
  GET_BLOCKED_DATES_BY_GROUP: (yachtId, numberOfMonths) =>
    `yachts/calendar?yacht_id=${yachtId}&months_count=${numberOfMonths}`, // GET
  GET_BLOCKED_DATES_BY_YACHT_IDS: (yachtIds, numberOfMonths, startDate) => {
    const yachtIdsParam = yachtIds.join('&yacht_ids[]=');
    return `yachts/calendar?${
      startDate ? `from_date=${startDate}&` : ''
    }months_count=${numberOfMonths}&yacht_ids[]=${yachtIdsParam}`; // GET
  },
  YACHT_AVAILABILITY: (yachtIds, from, to) => {
    const yachtIdsParams = yachtIds.join('&yacht_ids[]=');
    return `/yachts/available?date_from=${from}&date_to=${to}&yacht_ids[]=${yachtIdsParams}`;
  },
  // `/yachts/${yachtId}/available?date_from=${from}&date_to=${to}`, OLD
  SEND_QUESTION: yachtId => `/yachts/${yachtId}/send_message`,
  BOOK: yachtId => `/yachts/${yachtId}/book`,
  REGULATIONS: yachtId => `/yachts/${yachtId}/regulations_content`,
};

export { API, APIRoutes, setAuthenticationToken, setApiLanguage };
