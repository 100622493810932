import Cookies from 'universal-cookie';
import qs from 'query-string';

const { apikey } = qs.parse(window.location.search);

const COOKIE_PREFIX = 'YACHT:WDG::';

export const KEYS = {
  AUTH_TOKEN: 'AUTH_TOKEN',
};

const cookies = new Cookies();

export const getCookie = key => cookies.get(`${COOKIE_PREFIX}${key}:${apikey}`);

export const getAll = () => cookies.getAll();

export const setCookie = (key, value, expires) =>
  cookies.set(`${COOKIE_PREFIX}${key}:${apikey}`, value, {
    expires,
    secure: true,
    sameSite: 'none',
  });

export const removeCookie = key =>
  cookies.remove(`${COOKIE_PREFIX}${key}:${apikey}`, {
    secure: true,
    sameSite: 'none',
  });
