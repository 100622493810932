import React from 'react';
import { Global, css, useTheme } from '@emotion/react';

const CalendarStyles = () => {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        .DayPicker_weekHeaders {
          margin-left: 0;
        }

        .CalendarMonthGrid {
          left: 0;
          background-color: ${theme.calendar.month.background};
        }

        .DayPicker,
        .DayPicker__horizontal {
          background-color: ${theme.calendar.background};
        }

        .DayPickerNavigation_button {
          position: absolute;
          left: 10px;
          top: 14px;
          padding: 10px;

          img {
            display: block;
            width: 16px;
            height: 16px;
          }

          & + & {
            right: 10px;
            left: unset;
          }
        }

        .CalendarDay__default {
          border: none;
          padding: 0;
          background-color: ${theme.calendar.day.default.background};

          &:hover {
            border: none;
            background-color: transparent;

            > div {
              > span {
                border-color: rgba(0, 0, 0, 0.2);
              }
            }
          }
        }

        .CalendarMonth {
          background-color: ${theme.calendar.month.background};
        }

        .CalendarDay__blocked_calendar,
        .CalendarDay__blocked_calendar:active,
        .CalendarDay__blocked_calendar:hover {
          text-decoration: line-through;
          background-color: ${theme.calendar.day.blocked.background};
          border: none;

          &:hover {
            > div {
              > span {
                border-color: transparent;
              }
            }
          }
        }

        .CalendarDay__blocked_out_of_range,
        .CalendarDay__blocked_out_of_range:active,
        .CalendarDay__blocked_out_of_range:hover {
          text-decoration: line-through;
          background-color: ${theme.calendar.day.blocked.background};
          border: none;

          &:hover {
            > div {
              > span {
                border-color: transparent;
              }
            }
          }
        }

        .CalendarDay__selected,
        .CalendarDay__selected:active,
        .CalendarDay__selected:hover {
          background-color: ${theme.calendar.day.selected.range};

          > div {
            > span {
              background-color: ${theme.calendar.day.selected.background};
            }
          }
        }

        .CalendarDay__selected_span {
          background-color: ${theme.calendar.day.selected.range};

          &:hover {
            background-color: ${theme.calendar.day.selected.range};

            > div {
              > span {
                color: #484848;
                background-color: #fff;
              }
            }
          }
        }

        .CalendarDay__hovered_span {
          background-color: ${theme.calendar.day.selected.range};

          > div {
            > span {
              color: #fff;
            }
          }

          &:hover {
            background-color: ${theme.calendar.day.selected.range};
            border-bottom-right-radius: 50% !important;
            border-top-right-radius: 50% !important;

            > div {
              > span {
                border-color: #0096ff;
                background-color: #fff;
                color: #484848;
              }
            }
          }
        }

        .CalendarDay__before_hovered_end {
          background-color: ${theme.calendar.day.selected.range};
        }

        .CalendarDay__selected_start {
          border-bottom-left-radius: 50% !important;
          border-top-left-radius: 50% !important;
          background-color: ${theme.calendar.day.selected.range};
        }
        .CalendarDay__selected_end {
          border-bottom-right-radius: 50% !important;
          border-top-right-radius: 50% !important;
          background-color: ${theme.calendar.day.selected.range};
        }
      `}
    />
  );
};

export default CalendarStyles;
