import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import { inject, observer } from 'mobx-react';
import { useLocation } from 'react-router-dom';
import { DayPickerRangeController } from 'react-dates';
import { useSwipeable } from 'react-swipeable';
import queryString from 'query-string';
import moment from 'moment';
import 'moment/locale/pl';
import 'moment/locale/de';
import LeftChevronIcon from '@assets/left-chevron.svg';
import RightChevronIcon from '@assets/right-chevron.svg';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import CalendarActions from '@components/CalendarActions';
import { Day, Wrapper } from '@containers/Calendar/style';
import { formatDate } from '@utils/formatters';
import i18n from '@utils/i18n';
import { setApiLanguage } from '@api';

const Calendar = ({
  calendarStore: {
    blockAfter,
    blockedDays,
    currentFocusedInput,
    endDate,
    initCalendar,
    initialMonthDate,
    isInitialized,
    numberOfMonths,
    onDatesChangeHandler,
    onNextMonthClickHandler,
    setCurrentFocusedInput,
    setInitialMonth,
    startDate,
    calendarOrientation,
    calendarDaySize,
    screenResizeHandler,
    error: calendarError,
    firstAvailableDate,
    wrapperPaddingLeft,
  },
  widgetStore: { reinitialize, init, error, toggleReinitialized },
}) => {
  const { search } = useLocation();
  const calendar = useRef();
  const nextMonthIcon = useRef();
  const prevMonthIcon = useRef();
  const queryParams = queryString.parse(search);
  const today = useMemo(() => moment(), []);

  useEffect(() => {
    // set language
    moment.locale(queryParams.lang);
    i18n.changeLanguage(queryParams.lang);
    setApiLanguage(queryParams.lang);

    const initWidget = async () => {
      await init(queryParams);
      await initCalendar(queryParams);
    };

    initWidget();

    window.addEventListener('resize', screenResizeHandler);

    return () => {
      window.removeEventListener('resize', screenResizeHandler);
    };
  }, []);

  useEffect(() => {
    if (reinitialize) {
      const reInitWidget = async () => {
        await init(queryParams);
        await initCalendar(queryParams);
        toggleReinitialized();
      };
      reInitWidget();
    }
  }, [reinitialize]);

  const isDayBlocked = useCallback(
    day => {
      const d = moment(day);
      const formattedDay = formatDate(d);

      return (
        blockedDays[formattedDay] === '' ||
        (blockedDays[formattedDay] === 'F' && today.isSame(day, 'day')) ||
        day.isSameOrAfter(blockAfter) ||
        d.isBefore(firstAvailableDate)
      );
    },
    [blockedDays, today, firstAvailableDate, blockAfter],
  );

  const renderDayHandler = useCallback(
    (day, status) => {
      const dayToBeRendered = day.format('D');
      const formattedDay = formatDate(day);
      const firstDay =
        !status.has('blocked-calendar') &&
        blockedDays[formattedDay] === 'F' &&
        !today.isSame(day, 'day');
      const dayBefore = moment(day).add('-1', 'day');
      const lastDay =
        (!status.has('blocked-calendar') &&
          blockedDays[formattedDay] === 'L') ||
        (!blockedDays[formattedDay] && day.isBefore(today)) ||
        dayBefore.isBefore(today, 'day');

      return (
        <Day
          status={status}
          firstInRange={firstDay ? 1 : 0}
          lastInRange={lastDay ? 1 : 0}
        >
          <span>{dayToBeRendered}</span>
        </Day>
      );
    },
    [blockedDays, today],
  );

  const swipeMonthChangeHandler = ({ dir }) => {
    if (dir === 'Right') {
      prevMonthIcon.current.parentElement.click();
    }
    if (dir === 'Left') {
      nextMonthIcon.current.parentElement.click();
    }
  };

  const swipeHandlers = useSwipeable({
    onSwiped: swipeMonthChangeHandler,
  });

  return (
    <Wrapper
      paddingLeft={wrapperPaddingLeft}
      withMinHeight={!!startDate}
      daySize={calendarDaySize}
      {...swipeHandlers}
    >
      {error || calendarError || null}
      {!error && !calendarError && isInitialized ? (
        <DayPickerRangeController
          ref={calendar}
          startDate={startDate}
          endDate={endDate}
          onDatesChange={onDatesChangeHandler}
          focusedInput={currentFocusedInput}
          onFocusChange={setCurrentFocusedInput}
          orientation={calendarOrientation}
          numberOfMonths={numberOfMonths}
          isOutsideRange={d => moment(d).isBefore(moment(), 'day')}
          isDayBlocked={isDayBlocked}
          hideKeyboardShortcutsPanel
          noBorder
          navPrev={<img src={LeftChevronIcon} ref={prevMonthIcon} alt="" />}
          navNext={<img src={RightChevronIcon} ref={nextMonthIcon} alt="" />}
          renderDayContents={renderDayHandler}
          daySize={calendarDaySize}
          onNextMonthClick={onNextMonthClickHandler}
          onPrevMonthClick={setInitialMonth}
          initialVisibleMonth={() => {
            if (initialMonthDate) {
              return initialMonthDate;
            }
            return moment();
          }}
        />
      ) : null}
      {startDate ? <CalendarActions showSelectButton={!!endDate} /> : null}
    </Wrapper>
  );
};

Calendar.propTypes = {
  calendarStore: PropTypes.shape({
    blockAfter: PropTypes.oneOfType([
      MomentPropTypes.momentObj,
      PropTypes.string,
    ]),
    blockedDays: PropTypes.object,
    currentFocusedInput: PropTypes.oneOf(['startDate', 'endDate']),
    endDate: MomentPropTypes.momentObj,
    initCalendar: PropTypes.func,
    initialMonthDate: PropTypes.object,
    isInitialized: PropTypes.bool,
    numberOfMonths: PropTypes.number,
    onDatesChangeHandler: PropTypes.func,
    onNextMonthClickHandler: PropTypes.func,
    setCurrentFocusedInput: PropTypes.func,
    setInitialMonth: PropTypes.func,
    startDate: MomentPropTypes.momentObj,
    calendarOrientation: PropTypes.oneOf(['horizontal', 'vertical']),
    calendarDaySize: PropTypes.number,
    screenResizeHandler: PropTypes.func,
    error: PropTypes.any,
    firstAvailableDate: PropTypes.object,
    wrapperPaddingLeft: PropTypes.number,
  }).isRequired,
  widgetStore: PropTypes.shape({
    reinitialize: PropTypes.bool,
    init: PropTypes.func,
    error: PropTypes.any,
    toggleReinitialized: PropTypes.func,
  }).isRequired,
};

export default inject('calendarStore', 'widgetStore')(observer(Calendar));
