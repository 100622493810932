import merge from 'lodash/merge';
import variants, { THEMES } from '@styles/theme/variants';
import palette from '@styles/theme/palette';

const createTheme = (name = THEMES.DEFAULT, customStyle) => {
  let themeConfig = variants.find(variant => variant.name === name);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`)); // eslint-disable-line no-console
    [themeConfig] = variants;
  }

  let colorPalette = palette;
  let theme;
  if (customStyle) {
    if (customStyle.palette) {
      colorPalette = { ...palette, ...customStyle.palette };
    }

    theme = merge(themeConfig.theme(colorPalette), customStyle);
  } else {
    theme = themeConfig.theme(colorPalette);
  }

  return theme;
};

export default createTheme;
